<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <label>Kasus {{ tipeKasus }}</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field
          label="Nama kasus"
          :error-messages="kasusIndErr"
          solo
          required
          :counter="50"
          v-model="kasus"
          @input="$v.kasus.$touch()"
          @blur="$v.kasus.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <label>Deskripsi kasus</label>
      </v-col>
      <v-col cols="12" md="9" class="mb-3">
        <vue-editor
          v-model="narasi"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </v-col>
    </v-row>
    <v-row class="mt-n5">
      <v-col cols="12" md="3">
        <label>Tipe data</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-select
          :items="selectChart"
          :error-messages="chartErr"
          v-model="chart"
          item-text="state"
          item-value="abbr"
          solo
          required
          label="Pilih tipe chart..."
          @change="$v.chart.$touch()"
          @blur="$v.chart.$touch()"
        ></v-select>
        <div>
          <div v-if="chart === 'line'">
            <a
              :href="`${env}/storage/format_kasus/contoh1_line.xlsx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="green"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-excel"
              >
                <span class="d-flex justify-space-between">
                  Template line chart
                  <v-icon color="green"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
          </div>
          <div v-if="chart === 'bar'">
            <a
              :href="`${env}/storage/format_kasus/contoh1_bar.xlsx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="green"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-excel"
              >
                <span class="d-flex justify-space-between">
                  Template bar chart
                  <v-icon color="green"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
          </div>
          <div v-if="chart === 'text'">
            <a
              :href="`${env}/storage/format_kasus/contoh1_text.xlsx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="green"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-excel"
              >
                <span class="d-flex justify-space-between">
                  Template text
                  <v-icon color="green"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-n5">
      <v-col cols="12" md="3">
        <label>Unggah Dokumen</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-file-input
          v-model="files"
          color="deep-purple accent-4"
          :error-messages="filesErr"
          counter
          label="Dokumen input"
          placeholder="Pilih dokumen data kasus"
          hide-details="auto"
          solo
          v-if="chart"
          :show-size="1000"
          @input="$v.files.$touch()"
          @blur="$v.files.$touch()"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
        <v-btn color="indigo" dark @click="createDataCase()" v-if="!loader"
          ><v-icon>mdi-plus</v-icon> Tambah data kasus</v-btn
        >
        <v-btn disabled v-else>loading</v-btn>
        <section
          v-for="kasus in modulKasusByModulId"
          :key="kasus.id"
          class="mt-3"
        >
          <v-alert
            border="left"
            :color="kasus.fase === 'KELOMPOK' ? 'red' : 'indigo'"
            elevation="2"
            colored-border
            icon="mdi-file-document-outline"
            class="pa-2"
          >
            <div class="d-flex justify-space-between align-center">
              <section>
                <h4>{{ kasus.kasus }} | {{ kasus.file }}</h4>
                <small class="grey--text">kasus {{ kasus.fase }} </small>
              </section>
              <v-btn small text color="red" @click="delCase(kasus)"
                ><v-icon color="red">mdi-delete</v-icon></v-btn
              >
            </div>
          </v-alert>
        </section>
      </v-col>
    </v-row>
    <PreviewChart
      v-bind:dataCaseId="dataCaseId"
      v-bind:chartType="chartType"
      v-bind:dialogChart="dialogChart"
      v-on:false="dialogFalse"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  CREATE_DATACASE,
  CASE_BY_MODULE,
  DEL_KASUS
} from "../../../graphql/Module";
import PreviewChart from "../Modal/previewLine";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
  apollo: {
    modulKasusByModulId: {
      variables() {
        return { modul_id: this.module };
      },
      skip() {
        return !this.module;
      },
      query: CASE_BY_MODULE,
      error(error) {
        console.error(error);
      }
    }
  },
  components: {
    PreviewChart,
    VueEditor
  },
  mixins: [validationMixin],
  validations: {
    kasus: { required },
    files: { required },
    chart: { required }
  },
  computed: {
    module() {
      return parseInt(this.modul_id);
    },
    chartErr() {
      const errors = [];
      if (!this.$v.chart.$dirty) return errors;
      !this.$v.chart.required && errors.push("chart harus di pilih!");
      return errors;
    },
    kasusIndErr() {
      const errors = [];
      if (!this.$v.kasus.$dirty) return errors;
      !this.$v.kasus.required && errors.push("kasus tidak boleh kosong!");
      return errors;
    },
    filesErr() {
      const errors = [];
      if (!this.$v.files.$dirty) return errors;
      !this.$v.files.required && errors.push("dokumen tidak boleh kosong!");
      return errors;
    },
    tipeKasus() {
      let tipe = this.type;
      if (tipe == "INDIVIDU") {
        tipe = "individu";
      }
      if (tipe == "KELOMPOK") {
        tipe = "kelompok";
      }
      return tipe;
    }
  },
  props: ["type", "modul_id"],
  data: () => ({
    modulKasusByModulId: null,
    env: process.env.VUE_APP_GRAPHQL,
    dataCaseId: null,
    chartType: "",
    narasi: "",
    files: null,
    kasus: "",
    chart: null,
    loader: false,
    dialogChart: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ color: [] }]
    ],
    selectChart: [
      { state: "Line chart", abbr: "line" },
      { state: "Bar chart", abbr: "bar" },
      { state: "Text", abbr: "text" }
    ]
  }),
  methods: {
    createDataCase() {
      this.$v.files.$touch();
      this.$v.chart.$touch();
      if (!this.$v.files.required && !this.$v.chart.require) {
        return;
      } else {
        this.loader = true;
        const modul_id = this.module;
        const fase = this.type;
        const file = this.files;
        const kasus = this.kasus;
        const chart = this.chart;
        this.$apollo
          .mutate({
            mutation: CREATE_DATACASE,
            variables: {
              modul_id: modul_id,
              fase: fase,
              file: file,
              narasi: this.narasi,
              kasus: kasus,
              chart: chart
            },
            context: {
              hasUpload: true
            }
          })
          .then(data => {
            this.dataCaseId = data.data.createModulKasus.id;
            this.chartType = data.data.createModulKasus.chart;
            this.dialogChart = true;
            this.$store.dispatch("previewChart", {
              id: data.data.createModulKasus.id,
              chart: data.data.createModulKasus.chart,
              q: null
            });
            localStorage.setItem("dataCaseId", this.dataCaseId);
            this.chart = null;
            this.files = null;
            this.kasus = "";
            this.narasi = "";
            this.$v.files.$reset();
            this.$v.kasus.$reset();
            this.$v.chart.$reset();
            this.loader = false;
            this.$apollo.queries.modulKasusByModulId.refetch();
            this.$emit("refetch");
          })
          .catch(err => {
            console.log(err.graphQLErrors);
            this.loader = false;
            Swal.fire({
                icon: "error",
                title: "Dokumen harus berbentuk excel",
                showConfirmButton: true,
                confirmButtonColor: "#3F51B5",
                confirmButtonText: "Ya",
                timer: 3000,
                position: "top",
                toast: true,
              });
            console.log("hai kamu");
          });
      }
    },
    delCase(item) {
      Swal.fire({
        title: "Yakin hapus kasus??",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak"
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.$apollo
            .mutate({
              mutation: DEL_KASUS,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.modulKasusByModulId.refetch();
              this.$emit("refetch");
              Swal.fire({
                icon: "success",
                title: "Data kasus telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    dialogFalse() {
      this.dialogChart = false;
    }
  }
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
