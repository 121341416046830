<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <label for="">Judul tugas</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field
          :error-messages="objErr"
          @input="$v.judul_tugas.$touch()"
          @blur="$v.judul_tugas.$touch()"
          v-model="judul_tugas"
          label="Tuliskan judul tugas.."
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Keterangan</v-col>
      <v-col cols="12" md="9">
        <vue-editor
          v-model="keterangan_tugas"
          placeholder="keterangan tugas"
          :editorToolbar="customToolbar"
        ></vue-editor>
        <small v-if="!this.$v.keterangan_tugas.required" class="red--text">{{
          KetTugasErr[0]
        }}</small>
        <div class="mt-3">
          <v-btn color="indigo" dark @click="addObjective()" v-if="!loader"
            ><v-icon>mdi-plus</v-icon> Tambah Tugas</v-btn
          >
          <v-btn disabled v-else>loading</v-btn>
          <section
            v-for="objective in modulTugasByModulId"
            :key="objective.id"
            class="mt-3"
          >
            <v-alert
              border="left"
              :color="objective.fase === 'KELOMPOK' ? 'red' : 'indigo'"
              elevation="2"
              colored-border
              icon="mdi-file-document-outline"
              class="pa-2"
            >
              <div class="d-flex justify-space-between align-center">
                <section>
                  <h4>{{ objective.judul }}</h4>
                  <small class="grey--text">kasus {{ objective.fase }} </small>
                </section>
                <v-btn small text color="red" @click="delObjective(objective)"
                  ><v-icon color="red">mdi-delete</v-icon></v-btn
                >
              </div>
            </v-alert>
          </section>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  CREATE_OBJECTIVE,
  DEL_OBJECTIVE,
  OBJECTIVE_BY_MODULE
} from "../../../graphql/Module";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
export default {
  name: "objective",
  apollo: {
    modulTugasByModulId: {
      variables() {
        return { modul_id: this.module };
      },
      skip() {
        return !this.module;
      },
      query: OBJECTIVE_BY_MODULE,
      error(error) {
        console.error(error);
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    judul_tugas: { required },
    keterangan_tugas: { required }
  },
  components: {
    VueEditor
  },
  props: ["type", "modul_id"],
  computed: {
    module() {
      return parseInt(this.modul_id);
    },
    objErr() {
      const errors = [];
      if (!this.$v.judul_tugas.$dirty) return errors;
      !this.$v.judul_tugas.required &&
        errors.push("judul tugas tidak boleh kosong!");
      return errors;
    },
    KetTugasErr() {
      const errors = [];
      if (!this.$v.keterangan_tugas.$dirty) return errors;
      !this.$v.keterangan_tugas.required &&
        errors.push("keterangan tugas tidak boleh kosong!");
      return errors;
    }
  },
  data() {
    return {
      judul_tugas: "",
      keterangan_tugas: "",
      modulTugasByModulId: null,
      loader: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ]
    };
  },
  mounted() {},
  methods: {
    addObjective() {
      this.$v.judul_tugas.$touch();
      this.$v.keterangan_tugas.$touch();
      if (!this.$v.judul_tugas.required && !this.$v.keterangan_tugas.required) {
        return;
      } else {
        this.loader = true;
        const modul_id = this.module;
        const modul_kasus_id = parseInt(localStorage.getItem("dataCaseId"));
        const fase = this.type;
        const judul = this.judul_tugas;
        const keterangan = this.keterangan_tugas;
        this.$apollo
          .mutate({
            mutation: CREATE_OBJECTIVE,
            variables: {
              modul_id: modul_id,
              modul_kasus_id: modul_kasus_id,
              fase: fase,
              judul: judul,
              keterangan: keterangan
            }
          })
          .then(() => {
            this.$apollo.queries.modulTugasByModulId.refetch();
            this.$emit("refetch");
            this.judul_tugas = "";
            this.keterangan_tugas = "";

            this.$v.$reset();
            this.loader = false;
          });
      }
    },
    delObjective(item) {
      Swal.fire({
        title: "Yakin hapus tugas?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.$apollo
            .mutate({
              mutation: DEL_OBJECTIVE,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.modulTugasByModulId.refetch();
              this.$emit("refetch");
              Swal.fire({
                icon: "success",
                title: "Tugas telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.ql-editor {
  min-height: 100px !important;
}
</style>
