<template>
  <v-dialog v-model="dialogChart" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Simulasi data</span>
      </v-card-title>
      <div class="pa-5">
        <div v-if="tipeChart === 'line'">
          <line-chart :data="chart" />
        </div>
        <div v-else-if="tipeChart === 'bar'">
          <column-chart :data="chart"></column-chart>
        </div>
        <div v-else>
          {{ chart }}
        </div>
      </div>
      <div class="d-flex">
        <span class="ma-3"> perubahan hari ke - </span>
        <v-btn
          class="ma-1"
          v-for="(q, idx) in visible"
          :key="idx"
          :color="curent == q ? 'indigo' : ''"
          :dark="curent == q"
          @click="change(q)"
          >{{ q }}</v-btn
        >
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="delKasus"> hapus </v-btn>
        <v-btn color="indigo" dark @click="$emit('false')"> simpan </v-btn>
      </v-card-actions>
    </v-card>
    <DialogLoading v-bind:loading="loading" />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { DEL_KASUS } from "../../../graphql/Module";
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
export default {
  name: "preview",
  props: ["dialogChart", "dataCaseId", "chartType"],
  data() {
    return {
      dataChart: null,
      curent: null,
      loading: false
    };
  },
  components: {
    DialogLoading
  },
  computed: {
    ...mapState(["chart", "visible"]),
    tipeChart() {
      return this.chartType;
    }
  },
  watch: {
    visible(val) {
      if (this.curent == null) return (this.curent = val[0]);
    }
  },
  methods: {
    change(q) {
      this.loading = true;
      this.curent = q;
      this.$store
        .dispatch("previewChart", {
          id: this.dataCaseId,
          chart: this.chartType,
          q
        })
        .then(() => {
          this.loading = false;
        });
    },
    delKasus() {
      Swal.fire({
        title: "Yakin hapus data kasus?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(this.dataCaseId);
          this.$apollo
            .mutate({
              mutation: DEL_KASUS,
              variables: {
                id: id
              }
            })
            .then(() => {
              // this.$apollo.queries.allModul.refetch();
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Data kasus telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style></style>
