<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <label for="">Judul berita</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field
          solo
          label="isi judul berita"
          v-model="judul_berita"
          :error-messages="newsErr"
          @input="$v.judul_berita.$touch()"
          @blur="$v.judul_berita.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- <v-row class="mb-12">
      <v-col cols="12" md="3"> <label for="">Ringkasan</label> </v-col>
      <v-col cols="12" md="9">
        <vue-editor
          v-model="ringkasan_berita"
          :editorToolbar="customToolbar"
          style="height: 100px"
        ></vue-editor>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12" md="3">Isi berita</v-col>
      <v-col cols="12" md="9">
        <v-select
          :items="selectType"
          v-model="tipe"
          item-text="state"
          item-value="abbr"
          solo
          hide-details
          required
          label="pilih tipe.."
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="tipe == 'txt'">
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="9">
        <vue-editor
          placeholder="Isi berita.."
          v-model="isi_berita"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </v-col>
    </v-row>

    <v-row v-if="tipe == 'link'">
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="9">
        <v-text-field
          solo
          v-model="link_berita"
          label="Link berita"
          hide-details=""
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="9">
        <v-btn color="indigo" dark @click="createBerita()" v-if="!loader"
          ><v-icon>mdi-plus</v-icon> Tambah Informasi pendukung</v-btn
        >
        <v-btn disabled v-else>loading</v-btn>

        <section v-for="news in beritaByModulId" :key="news.id" class="mt-3">
          <v-alert
            border="left"
            color="indigo"
            elevation="2"
            colored-border
            icon="mdi-file-document-outline"
            class="pa-2 mb-2"
          >
            <div class="d-flex justify-space-between align-center">
              <section>
                <h4>{{ news.judul }}</h4>
                <!-- <small class="grey--text">kasus {{ kasus.fase }} </small> -->
              </section>
              <v-btn small text color="red" @click="delNews(news)"
                ><v-icon color="red">mdi-delete</v-icon></v-btn
              >
            </div>
          </v-alert>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";
import {
  CREATE_BERITA,
  DEL_NEWS,
  NEWS_BY_MODULE
} from "../../../graphql/Module";

export default {
  name: "suportingNews",

  apollo: {
    beritaByModulId: {
      variables() {
        return { modul_id: this.module };
      },
      skip() {
        return !this.module;
      },
      query: NEWS_BY_MODULE,
      error(error) {
        console.error(error);
      }
    }
  },
  components: {
    VueEditor
  },
  props: ["modul_id"],
  mixins: [validationMixin],
  validations: {
    judul_berita: { required }
  },
  computed: {
    module() {
      return parseInt(this.modul_id);
    },
    newsErr() {
      const errors = [];
      if (!this.$v.judul_berita.$dirty) return errors;
      !this.$v.judul_berita.required &&
        errors.push("judul berita tidak boleh kosong!");
      return errors;
    }
  },
  data() {
    return {
      judul_berita: "",
      ringkasan_berita: "",
      isi_berita: "",
      link_berita: "",
      beritaByModulId: null,
      loader: false,
      tipe: "link",
      selectType: [
        { state: "Link", abbr: "link" },
        { state: "Teks", abbr: "txt" }
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ]
    };
  },
  methods: {
    createBerita() {
      this.$v.judul_berita.$touch();
      if (!this.$v.judul_berita.required) {
        return;
      } else {
        this.loader = true;
        const modul_id = this.module;
        const judul = this.judul_berita;
        const ringkasan_berita = this.ringkasan_berita;
        const isi_berita = this.isi_berita;
        const link = this.link_berita;
        this.$apollo
          .mutate({
            mutation: CREATE_BERITA,
            variables: {
              modul_id: modul_id,
              judul: judul,
              ringkasan_berita: ringkasan_berita,
              isi_berita: isi_berita,
              link: link
            }
          })
          .then(() => {
            this.$apollo.queries.beritaByModulId.refetch();
            this.$emit("refetch");
            [
              this.judul_berita,
              this.ringkasan_berita,
              this.isi_berita,
              this.link_berita
            ] = "";
            this.$v.judul_berita.$reset();
            this.loader = false;
          });
      }
    },
    delNews(item) {
      Swal.fire({
        title: "Yakin hapus Informasi pendukung?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.$apollo
            .mutate({
              mutation: DEL_NEWS,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.beritaByModulId.refetch();
              this.$emit("refetch");
              Swal.fire({
                icon: "success",
                title: "Informasi pendukung telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style></style>
