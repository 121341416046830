<template>
  <div class="bg-default">
    <nav class="pa-3 d-flex justify-space-between">
      <v-btn @click="back" dark fab small class="mr-5" color="indigo">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-alert type="error">
        Disarankan untuk tidak keluar dari aplikasi saat mengisi modul
      </v-alert>
      <div></div>
    </nav>
    <section class="pa-3">
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" color="indigo">
            Nama modul
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2" color="indigo">
            Narasi
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3" color="indigo">
            Data Kasus
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step :complete="step > 4" step="4" color="indigo">
           Informasi Pendukung
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step :complete="step > 5" step="5" color="indigo">
            Tugas
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step :complete="step > 6" step="6" color="indigo">
            Preview
          </v-stepper-step>
        </v-stepper-header>
        <!-- STEP 1 -->
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-12 pa-3" elevation="0">
              <v-row>
                <v-col cols="12" md="3">
                  <label for="moduleName">Nama Modul</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="nama_modul"
                    :error-messages="modulNameErr"
                    :counter="100"
                    required
                    solo
                    @input="$v.nama_modul.$touch()"
                    @blur="$v.nama_modul.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <label for="moduleName">Tipe Modul</label>
                </v-col>
                <v-col cols="12" md="9" v-if="diss">
                  <v-text-field v-model="diss" disabled solo></v-text-field>
                </v-col>
                <v-col cols="12" md="9" v-else>
                  <v-select
                    :items="typeModule"
                    :error-messages="phaseErr"
                    v-model="type"
                    item-text="state"
                    item-value="abbr"
                    solo
                    required
                    label="Pilih tipe modul..."
                    @change="$v.type.$touch()"
                    @blur="$v.type.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>
            <section v-if="!modul_id">
              <v-btn color="error" @click="delModule()"> Reset </v-btn>
              <v-btn color="indigo" class="ml-3" dark @click="step1">
                Lanjut
              </v-btn>
            </section>
            <section v-else>
              <v-btn color="error" @click="delModule()"> Reset </v-btn>
              <v-btn color="indigo" class="ml-3" dark @click="updateModule">
                Lanjut
              </v-btn>
            </section>
          </v-stepper-content>
          <!-- STEP 2 -->
          <v-stepper-content step="2">
            <v-card class="mb-12 pa-3" elevation="0">
              <v-row v-if="type == 'INDIVIDU'">
                <v-col cols="12" md="3">
                  <label for="moduleName">Narasi {{ tipeNarasi }}</label>
                </v-col>
                <v-col cols="12" md="9">
                  <vue-editor
                    v-model="narasi_individu"
                    :editorToolbar="customToolbar"
                    @input="$v.narasi_individu.$touch()"
                    @blur="$v.narasi_individu.$touch()"
                  ></vue-editor>
                  <small
                    v-if="!this.$v.narasi_individu.required"
                    class="red--text"
                    >{{ narasiIndErr[0] }}</small
                  >
                </v-col>
              </v-row>
              <v-row v-if="type == 'KELOMPOK'">
                <v-col cols="12" md="3">
                  <label for="moduleName">Narasi {{ tipeNarasi }}</label>
                </v-col>
                <v-col cols="12" md="9">
                  <vue-editor
                    v-model="narasi_kelompok"
                    :editorToolbar="customToolbar"
                    @input="$v.narasi_kelompok.$touch()"
                    @blur="$v.narasi_kelompok.$touch()"
                  ></vue-editor>
                  <small
                    v-if="!this.$v.narasi_individu.required"
                    class="red--text"
                    >{{ narasiKelErr[0] }}</small
                  >
                </v-col>
              </v-row>
            </v-card>

            <v-btn color="error" @click="backStep(1)"> Kembali </v-btn>
            <v-btn color="error" class="ml-3" @click="delModule()">
              Reset
            </v-btn>
            <v-btn color="indigo" dark class="ml-3" @click="step2">
              Lanjut
            </v-btn>
          </v-stepper-content>
          <!-- STEP 3 -->
          <v-stepper-content step="3">
            <v-card class="mb-12 pa-3" elevation="0">
              <DataCase
                v-bind:type="type"
                v-bind:modul_id="modul_id"
                v-on:refetch="refetchModule"
              />
            </v-card>

            <v-btn color="error" @click="backStep(2)"> Kembali </v-btn>
            <v-btn color="error" class="ml-3" @click="delModule()">
              Reset
            </v-btn>

            <v-btn
              v-if="dataCase > 0"
              color="indigo"
              dark
              class="ml-3"
              @click="nextStep(4)"
            >
              Lanjut
            </v-btn>
          </v-stepper-content>
          <!-- STEP 4 -->
          <v-stepper-content step="4">
            <v-card class="mb-12 pa-3" elevation="0">
              <SuportingNews
                v-bind:modul_id="modul_id"
                v-on:refetch="refetchModule"
              />
            </v-card>

            <v-btn color="error" @click="backStep(3)"> Kembali </v-btn>
            <v-btn color="error" class="ml-3" @click="delModule()">
              Reset
            </v-btn>
            <v-btn
              v-if="dataNews > 0"
              color="indigo"
              dark
              class="ml-3"
              @click="nextStep(5)"
            >
              Lanjut
            </v-btn>
          </v-stepper-content>
          <!-- STEP 5 -->
          <v-stepper-content step="5">
            <v-card class="mb-12 pa-3" elevation="0">
              <Objective
                v-bind:type="type"
                v-bind:modul_id="modul_id"
                v-on:refetch="refetchModule"
              />
            </v-card>

            <v-btn color="error" @click="backStep(4)"> Kembali </v-btn>
            <v-btn color="error" class="ml-3" @click="delModule()">
              Reset
            </v-btn>
            <span v-if="join">
              <v-btn
                v-if="dataObjective > 0"
                color="indigo"
                dark
                class="ml-3"
                @click="joinModule(2)"
              >
                Lanjut
              </v-btn>
            </span>
            <span v-else>
              <v-btn
                v-if="dataObjective > 0"
                color="indigo"
                dark
                class="ml-3"
                @click="nextStep(6)"
              >
                Lanjut
              </v-btn>
            </span>
          </v-stepper-content>
          <!-- STEP 6 -->
          <v-stepper-content step="6">
            <v-card class="mb-12 pa-3" elevation="0">
              <PreviewModule v-bind:modulById="modulById" />
            </v-card>

            <v-btn color="error" @click="backStep(5)"> Kembali </v-btn>
            <v-btn color="indigo" dark class="ml-3" @click="finishModule()">
              Selesai
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </section>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

import DataCase from "./Module/dataCase";
import SuportingNews from "./Module/supportingNews";
import Objective from "./Module/Objective";
import PreviewModule from "./Module/PreviewModule";

import {
  MODULE_BY_ID,
  CREATE_MODULE,
  UPDATE_MODULE,
  DEL_MODULE
} from "../../graphql/Module";
import Swal from "sweetalert2";

export default {
  name: "steper",
  apollo: {
    modulById: {
      variables() {
        return { id: this.modul_id };
      },
      result(data) {
        if (data) {
          this.nama_modul = data.data.modulById.nama_modul;
          this.narasi_individu = data.data.modulById.narasi_individu;
          this.narasi_kelompok = data.data.modulById.narasi_kelompok;
          this.dataCase = data.data.modulById.modul_kasus.length;
          this.dataNews = data.data.modulById.berita.length;
          this.dataObjective = data.data.modulById.modul_tugas.length;
          this.diss = data.data.modulById.tipe;
        }
      },
      skip() {
        return !this.modul_id;
      },
      query: MODULE_BY_ID,
      error(error) {
        console.error(error);
      }
    }
  },
  components: {
    VueEditor,
    DataCase,
    SuportingNews,
    Objective,
    PreviewModule
  },
  mixins: [validationMixin],
  validations: {
    nama_modul: { required },
    type: { required },
    narasi_individu: { required },
    narasi_kelompok: { required },

    kasus_individu: { required },
    files: { required },
    chart: { required },

    judul_berita: { required },

    judul_tugas: { required },
    keterangan_tugas: { required }
  },

  computed: {
    modulNameErr() {
      const errors = [];
      if (!this.$v.nama_modul.$dirty) return errors;
      !this.$v.nama_modul.required &&
        errors.push("Nama modul tidak boleh kosong!");
      return errors;
    },
    phaseErr() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required && errors.push("Fase harus di pilih!");
      return errors;
    },
    narasiIndErr() {
      const errors = [];
      if (!this.$v.narasi_individu.$dirty) return errors;
      !this.$v.narasi_individu.required &&
        errors.push("Narasi tidak boleh kosong!");
      return errors;
    },
    narasiKelErr() {
      const errors = [];
      if (!this.$v.narasi_kelompok.$dirty) return errors;
      !this.$v.narasi_kelompok.required &&
        errors.push("Narasi tidak boleh kosong!");
      return errors;
    },
    // config error vuelidate end ---------------------
    tipeNarasi() {
      let tipe = this.type;
      if (tipe == "INDIVIDU") {
        tipe = "individu";
      }
      if (tipe == "KELOMPOK") {
        tipe = "kelompok";
      }
      return tipe;
    }
  },
  data() {
    return {
      step: 1,
      modulById: null,
      teacher_id: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      //v-model create modul
      fase: null,
      nama_modul: "",
      modul_id: null,
      narasi_individu: "",
      narasi_kelompok: "",
      narasi: "",
      typeModule: [
        { state: "Individu", abbr: "INDIVIDU" },
        { state: "Kelompok", abbr: "KELOMPOK" },
        { state: "Individu & Kelompok", abbr: "INDIVIDUKELOMPOK" }
      ],
      type: null,
      modulType: null,
      //v-model  modul kasus
      dataCase: null,
      //v-model  berita
      dataNews: null,
      //v-model  tugas
      dataObjective: null,
      //etc
      editorData: "",
      loader: false,
      join: null,
      hideDetails: false,
      diss: null
    };
  },
  mounted() {
    this.teacher_id = localStorage.getItem("id");
    if (localStorage.getItem("step")) {
      this.step = localStorage.getItem("step");
    }
    if (localStorage.getItem("moduleType")) {
      this.type = localStorage.getItem("moduleType");
    }
    if (localStorage.getItem("moduleID")) {
      this.modul_id = localStorage.getItem("moduleID");
    }
    if (localStorage.getItem("join")) {
      this.join = JSON.parse(localStorage.getItem("join"));
    }
  },
  methods: {
    nextStep(step) {
      this.step = step;
      localStorage.setItem("step", step);
    },
    backStep(step) {
      this.step = step;
      localStorage.setItem("step", step);
    },
    back() {
      this.$router.push("list-module");
    },
    joinModule() {
      this.step = 2;
      localStorage.setItem("step", this.step);
      this.type = "KELOMPOK";
      localStorage.setItem("moduleType", this.type);
      this.join = false;
      localStorage.setItem("join", false);
    },
    refetchModule() {
      this.$apollo.queries.modulById.refetch();
    },
    step1() {
      this.$v.nama_modul.$touch();
      this.$v.type.$touch();
      if (!this.$v.nama_modul.required && !this.$v.type.required) {
        console.log("i think my brain just commited suicide");
        return;
      } else {
        this.loader = true;
        const teacher_id = parseInt(this.teacher_id);
        const nama_module = this.nama_modul;
        const tipe = this.type;
        this.$apollo
          .mutate({
            mutation: CREATE_MODULE,
            variables: {
              teacher_id: teacher_id,
              nama_modul: nama_module,
              tipe: tipe
            }
          })
          .then(data => {
            this.nama_modul = "";
            this.$apollo.queries.modulById.refetch({
              id: data.data.createModul.id
            });
            this.modul_id = data.data.createModul.id;
            localStorage.setItem("moduleID", this.modul_id);
            localStorage.setItem("type", data.data.createModul.tipe);
            this.loader = false;
            localStorage.setItem("disabletype", true);
            if (this.type === "INDIVIDUKELOMPOK") {
              this.join = true;
              localStorage.setItem("join", true);
              this.type = "INDIVIDU";
              localStorage.setItem("moduleType", this.type);
            } else if (localStorage.getItem("loop")) {
              this.type = "KELOMPOK";
              localStorage.setItem("moduleType", this.type);
            } else {
              localStorage.setItem("moduleType", this.type);
            }
            this.step = 2;
            localStorage.setItem("step", this.step);
          });
      }
    },
    updateModule() {
      this.loader = true;
      const teacher_id = parseInt(this.teacher_id);
      const id = parseInt(this.modul_id);
      const nama_module = this.nama_modul;
      const narasi_individu = this.narasi_individu;
      const narasi_kelompok = this.narasi_kelompok;
      this.$apollo
        .mutate({
          mutation: UPDATE_MODULE,
          variables: {
            id: id,
            teacher_id: teacher_id,
            nama_modul: nama_module,
            narasi_kelompok: narasi_kelompok,
            narasi_individu: narasi_individu
          }
        })
        .then(() => {
          console.log("ok");
          if (this.step == 1) {
            this.step = 2;
            localStorage.setItem("step", this.step);
          } else {
            this.step = 3;
            localStorage.setItem("step", this.step);
          }
        });
    },
    step2() {
      if (this.type == "INDIVIDU") {
        this.$v.narasi_individu.$touch();
        if (!this.$v.narasi_individu.required) {
          console.log("i think my brain just commited suicide");
          return;
        } else {
          this.updateModule();
        }
      } else {
        this.$v.narasi_kelompok.$touch();
        if (!this.$v.narasi_kelompok.required) {
          console.log("i think my brain just commited suicide");
          return;
        } else {
          this.updateModule();
        }
      }
    },
    finishModule() {
      Swal.fire({
        icon: "success",
        title: "Modul berhasil dibuat",
        showConfirmButton: false,
        toast: true,
        position: "top",
        timer: 2000
      }).then(() => {
        this.step = 1;
        localStorage.setItem("step", this.step);
        localStorage.removeItem("dataCaseId");
        localStorage.removeItem("disabletype");
        localStorage.removeItem("moduleType");
        localStorage.removeItem("moduleID");
        localStorage.removeItem("type");
        this.modul_id = null;
        this.modulById = null;
        this.dataCase = null;
        this.dataNews = null;
        this.dataObjective = null;
        this.diss = null;
        this.nama_modul = "";
        this.narasi_individu = "";
        this.narasi_kelompok = "";
        this.$router.push("list-module");
      });
    },
    delModule() {
      console.log(this.modulById);
      Swal.fire({
        title: "Yakin hapus modul?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(this.modulById.id);
          this.$apollo
            .mutate({
              mutation: DEL_MODULE,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.modulById.refetch();
              this.step = 1;
              localStorage.setItem("step", this.step);
              localStorage.removeItem("dataCaseId");
              localStorage.removeItem("disabletype");
              localStorage.removeItem("moduleType");
              localStorage.removeItem("moduleID");
              localStorage.removeItem("type");
              this.modul_id = null;
              this.modulById = null;
              this.dataCase = null;
              this.dataNews = null;
              this.dataObjective = null;
              this.diss = null;
              this.nama_modul = "";
              this.narasi_individu = "";
              this.narasi_kelompok = "";
              this.$router.push("list-module");
              Swal.fire({
                icon: "success",
                title: "Modul telah ter reset",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              if (!err) {
                Swal.fire({
                  icon: "error",
                  title: err,
                  showConfirmButton: false,
                  timer: 3000,
                  position: "top",
                  toast: true
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
