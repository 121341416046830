<template>
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card class="indigo" dark>
      <v-card-text>
        silahkan tunggu...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogLoading",
  props: ["loading"]
};
</script>

<style></style>
