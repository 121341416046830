<template>
  <div>
    <div v-if="!modulById">Loading</div>
    <div v-else>
      <v-card class="_card">
        <v-toolbar color="indigo" dense class="mb-3 d-flex justify-center">
          <span class="white--text">Pratinjau modul</span>
        </v-toolbar>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Nama modul</v-col>
          <v-col cols="12" md="8" class="_val">
            <span>{{ modulById.nama_modul }}</span>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="12" md="3">Tipe modul</v-col>
          <v-col cols="12" md="8" class="_val">
            <span>{{ modulById.tipe }}</span>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="_card"
        v-if="
          modulById.tipe === 'INDIVIDU' || modulById.tipe === 'INDIVIDUKELOMPOK'
        "
      >
        <v-toolbar color="indigo" dense class="mb-3 d-flex justify-center">
          <span class="white--text">INDIVIDU</span>
        </v-toolbar>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Narasi</v-col>
          <v-col cols="12" md="8" class="_val">
            <span v-html="modulById.narasi_individu"></span>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Data Kasus</v-col>
          <v-col cols="12" md="8" class="_val">
            <section v-for="kasus in modulById.modul_kasus" :key="kasus.id">
              <div v-if="kasus.fase === 'INDIVIDU'">
                <v-alert
                  border="right"
                  colored-border
                  color="indigo"
                  elevation="2"
                  class="pa-2 ma-1"
                >
                  <div class="d-flex justify-space-between">
                    <section>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file</v-icon>
                        <h3>{{ kasus.kasus }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file-excel</v-icon>
                        <h3>{{ kasus.file }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-chart-bar</v-icon>
                        <h3>{{ kasus.chart }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-clock</v-icon>
                        <h5>{{ kasus.fase }}</h5>
                      </div>
                    </section>
                  </div>
                </v-alert>
              </div>
            </section>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="pa-3">
          <v-col cols="12" md="3">Tugas</v-col>
          <v-col cols="12" md="8" class="_val">
            <section v-for="tugas in modulById.modul_tugas" :key="tugas.id">
              <div v-if="tugas.fase === 'INDIVIDU'">
                <v-alert
                  border="right"
                  colored-border
                  color="indigo"
                  elevation="2"
                  class="pa-2 ma-1"
                >
                  <div class="d-flex justify-space-between">
                    <section>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file</v-icon>
                        <h3>{{ tugas.judul }}</h3>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex">
                        <small class="mr-2">Keterangan : </small>
                        <small v-html="tugas.keterangan"></small>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-clock</v-icon>
                        <h5>{{ tugas.fase }}</h5>
                      </div>
                    </section>
                  </div>
                </v-alert>
              </div>
            </section>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="_card"
        v-if="
          modulById.tipe === 'KELOMPOK' || modulById.tipe === 'INDIVIDUKELOMPOK'
        "
      >
        <v-toolbar color="indigo" dense class="mb-3 d-flex justify-center">
          <span class="white--text">Kelompok</span>
        </v-toolbar>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Narasi</v-col>
          <v-col cols="12" md="8" class="_val">
            <span v-html="modulById.narasi_kelompok"></span>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Data Kasus</v-col>
          <v-col cols="12" md="8" class="_val">
            <section v-for="kasus in modulById.modul_kasus" :key="kasus.id">
              <div v-if="kasus.fase === 'KELOMPOK'">
                <v-alert
                  border="right"
                  colored-border
                  color="indigo"
                  elevation="2"
                  class="pa-2 ma-1"
                >
                  <div class="d-flex justify-space-between">
                    <section>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file</v-icon>
                        <h3>{{ kasus.kasus }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file-excel</v-icon>
                        <h3>{{ kasus.file }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-chart-bar</v-icon>
                        <h3>{{ kasus.chart }}</h3>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-clock</v-icon>
                        <h5>{{ kasus.fase }}</h5>
                      </div>
                    </section>
                  </div>
                </v-alert>
              </div>
            </section>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="pa-3">
          <v-col cols="12" md="3">Tugas</v-col>
          <v-col cols="12" md="8" class="_val">
            <section v-for="tugas in modulById.modul_tugas" :key="tugas.id">
              <div v-if="tugas.fase === 'KELOMPOK'">
                <v-alert
                  border="right"
                  colored-border
                  color="indigo"
                  elevation="2"
                  class="pa-2 ma-1"
                >
                  <div class="d-flex justify-space-between">
                    <section>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-file</v-icon>
                        <h3>{{ tugas.judul }}</h3>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex">
                        <small class="mr-2">Keterangan : </small>
                        <small v-html="tugas.keterangan"></small>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">mdi-clock</v-icon>
                        <h5>{{ tugas.fase }}</h5>
                      </div>
                    </section>
                  </div>
                </v-alert>
              </div>
            </section>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="_card">
        <v-toolbar color="indigo" dense class="mb-3 d-flex justify-center">
          <span class="white--text">Informasi Pendukung</span>
        </v-toolbar>
        <v-row class="mb-2 pa-3">
          <v-col cols="12" md="3">Informasi Pendukung</v-col>
          <v-col cols="12" md="8" class="_val">
            <section v-for="berita in modulById.berita" :key="berita.id">
              <v-alert
                border="right"
                colored-border
                color="indigo"
                elevation="2"
                class="pa-2 ma-1"
              >
                <div class="d-flex justify-space-between">
                  <section>
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-file</v-icon>
                      <h3>{{ berita.judul }}</h3>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex" v-if="berita.isi_berita !== ''">
                      <small class="mr-2">Keterangan : </small>
                      <small v-html="berita.isi_berita"></small>
                    </div>
                    <div class="d-flex" v-if="berita.link !== ''">
                      <v-icon class="mr-2">mdi-link</v-icon>
                      <h5>{{ berita.link }}</h5>
                    </div>
                  </section>
                </div>
              </v-alert>
            </section>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "previewModule",
  props: ["modulById"]
};
</script>

<style scoped>
._card {
  margin: 10px 5px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
}
._val {
  padding: 10px;
  margin: 5px;
  background: rgb(243, 243, 243);
  border-radius: 5px;
}
</style>
