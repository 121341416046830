import gql from "graphql-tag";

//CREATE MODUL
export const CREATE_MODULE = gql`
  mutation(
    $teacher_id: Int!
    $nama_modul: String!
    $tipe: tipe!
    $narasi_individu: String
    $narasi_kelompok: String
  ) {
    createModul(
      teacher_id: $teacher_id
      nama_modul: $nama_modul
      narasi_individu: $narasi_individu
      narasi_kelompok: $narasi_kelompok
      tipe: $tipe
    ) {
      id
      nama_modul
      narasi_individu
      narasi_kelompok
      tipe
    }
  }
`;

//CREATE DATA KASUS
export const CREATE_DATACASE = gql`
  mutation(
    $modul_id: Int
    $fase: fase
    $narasi: String
    $file: Upload
    $kasus: String
    $chart: String
  ) {
    createModulKasus(
      modul_id: $modul_id
      fase: $fase
      narasi: $narasi
      file: $file
      kasus: $kasus
      chart: $chart
    ) {
      id
      modul_id
      file
      kasus
      chart
    }
  }
`;

//CREATE BERITA
export const CREATE_BERITA = gql`
  mutation(
    $modul_id: Int
    $judul: String
    $ringkasan_berita: String
    $isi_berita: String
    $link: String
  ) {
    createBerita(
      modul_id: $modul_id
      judul: $judul
      ringkasan_berita: $ringkasan_berita
      isi_berita: $isi_berita
      link: $link
    ) {
      id
      judul
      ringkasan_berita
      isi_berita
      link
    }
  }
`;

//  CREATE TUGAS/OBJECTIVE
export const CREATE_OBJECTIVE = gql`
  mutation(
    $modul_id: Int!
    $modul_kasus_id: Int
    $judul: String!
    $keterangan: String!
    $fase: fase
  ) {
    createModulTugas(
      modul_id: $modul_id
      modul_kasus_id: $modul_kasus_id
      judul: $judul
      keterangan: $keterangan
      fase: $fase
    ) {
      id
      judul
      keterangan
      fase
    }
  }
`;

// GET LIST MODULE BY ID TEACHER

export const GET_MODULE_BY_TEACHER = gql`
  query($teacher_id: Int!) {
    modulByTeacher(teacher_id: $teacher_id) {
      id
      nama_modul
      narasi_individu
      narasi_kelompok
      tipe
      modul_kasus {
        id
        file
      }
      modul_tugas {
        id
        judul
        keterangan
      }
      berita {
        id
      }
    }
  }
`;

// GET LIST MODULE
export const GET_MODULE = gql`
  query($page: Int!, $first: Int!) {
    allModul(page: $page, first: $first) {
      data {
        id
        nama_modul
        narasi_individu
        narasi_kelompok
        tipe
        modul_kasus {
          id
          file
        }
        modul_tugas {
          id
          judul
          keterangan
        }
        berita {
          id
        }
        pelatihan {
          id
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;

// GET LIST MODULE
export const COUNT_MODULE = gql`
  query($page: Int!, $first: Int!) {
    allModul(page: $page, first: $first) {
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;

//GET MODULE BY ID
export const MODULE_BY_ID = gql`
  query($id: ID!) {
    modulById(id: $id) {
      id
      nama_modul
      narasi_individu
      narasi_kelompok
      tipe
      modul_kasus {
        id
        kasus
        file
        chart
        fase
      }
      modul_tugas {
        id
        judul
        keterangan
        fase
      }
      berita {
        id
        judul
        isi_berita
        link
      }
    }
  }
`;

//GET NARASI
export const NARASI = gql`
  query($id: ID!) {
    modulById(id: $id) {
      id
      narasi_individu
      narasi_kelompok
    }
  }
`;

//get data case by module id
export const CASE_BY_MODULE = gql`
  query($modul_id: Int!) {
    modulKasusByModulId(modul_id: $modul_id) {
      id
      kasus
      file
      chart
      fase
    }
  }
`;

//get data case by id
export const CASE_BY_ID = gql`
  query($id: ID!) {
    modulKasusById(id: $id) {
      id
      fase
      narasi
      file
      kasus
      chart
    }
  }
`;

//get data news by module id
export const NEWS_BY_MODULE = gql`
  query($modul_id: Int!) {
    beritaByModulId(modul_id: $modul_id) {
      id
      judul
      ringkasan_berita
      isi_berita
      link
    }
  }
`;

//get data objective by module id
export const OBJECTIVE_BY_MODULE = gql`
  query($modul_id: Int!) {
    modulTugasByModulId(modul_id: $modul_id) {
      id
      judul
      fase
      keterangan
    }
  }
`;

//UPDATE MODULE
export const UPDATE_MODULE = gql`
  mutation(
    $id: ID!
    $teacher_id: Int
    $nama_modul: String
    $narasi_kelompok: String
    $narasi_individu: String
  ) {
    updateModul(
      id: $id
      teacher_id: $teacher_id
      nama_modul: $nama_modul
      narasi_kelompok: $narasi_kelompok
      narasi_individu: $narasi_individu
    ) {
      id
      teacher_id
      nama_modul
      narasi_individu
      narasi_kelompok
      tipe
    }
  }
`;

//UPDATE BERITA
export const UPDATE_BERITA = gql`
  mutation(
    $id: ID!
    $modul_id: Int
    $judul: String
    $link: String
    $isi_berita: String
  ) {
    updateBerita(
      id: $id
      modul_id: $modul_id
      judul: $judul
      link: $link
      isi_berita: $isi_berita
    ) {
      id
    }
  }
`;

//UPDATE TUGAS
export const UPDATE_TUGAS = gql`
  mutation(
    $id: ID!
    $modul_id: Int
    $modul_kasus_id: Int
    $judul: String
    $fase: fase
    $keterangan: String
  ) {
    updateModulTugas(
      id: $id
      modul_id: $modul_id
      modul_kasus_id: $modul_kasus_id
      judul: $judul
      fase: $fase
      keterangan: $keterangan
    ) {
      id
    }
  }
`;

//DELETE
export const DEL_MODULE = gql`
  mutation($id: ID!) {
    deleteModul(id: $id) {
      id
    }
  }
`;

export const DEL_KASUS = gql`
  mutation($id: ID!) {
    deleteModulKasus(id: $id) {
      id
    }
  }
`;

export const DEL_OBJECTIVE = gql`
  mutation($id: ID!) {
    deleteModulTugas(id: $id) {
      id
    }
  }
`;

export const DEL_NEWS = gql`
  mutation($id: ID!) {
    deleteBerita(id: $id) {
      id
    }
  }
`;
